import { STORAGE_KEY } from "axios-jwt/dist/src/StorageKey";

export const useLocalStorage = () => {
  const REFERRER_KEY = "referrer";

  const setReferrer = (path: string) => {
    localStorage.setItem(REFERRER_KEY, path);
  };

  const getReferrer = (): string => {
    const referrer = localStorage.getItem(REFERRER_KEY);
    if (!referrer) {
      return "/";
    }
    localStorage.removeItem(REFERRER_KEY);

    return referrer;
  };

  const clearLocalStorage = () => {
    localStorage.removeItem(STORAGE_KEY);
    localStorage.removeItem("oauth");
    localStorage.removeItem("authentication");
    localStorage.removeItem("registration");
    localStorage.removeItem("training");
    localStorage.removeItem("_grecaptcha");
  };

  return {
    clearLocalStorage,
    setReferrer,
    getReferrer,
  };
};
